import { SVGProps } from "react";
type Props = {
  height?: string;
  width?: string;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
export const Spinner = ({
  height = "33",
  width = "32",
  color,
  className,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16.5" r="14.5" stroke="#F0F0F0" stroke-width="3" />
      <mask id="path-2-inside-1_38_114" fill="white">
        <path d="M4.68629 5.18629C6.38015 3.49243 8.43274 2.20053 10.6926 1.40592C12.9524 0.611304 15.3619 0.334261 17.7431 0.595232C20.1243 0.856203 22.4165 1.64853 24.4506 2.91374C26.4847 4.17895 28.2088 5.88475 29.4956 7.90527C30.7824 9.9258 31.599 12.2095 31.8853 14.5878C32.1716 16.9661 31.9202 19.3783 31.1497 21.6465C30.3792 23.9147 29.1092 25.9809 27.4334 27.6927C25.7577 29.4045 23.719 30.7181 21.4677 31.5368L20.4443 28.7223C22.2742 28.057 23.9314 26.9892 25.2935 25.5978C26.6556 24.2064 27.6878 22.5269 28.3141 20.6832C28.9405 18.8396 29.1448 16.8789 28.9121 14.9457C28.6794 13.0125 28.0156 11.1563 26.9696 9.51394C25.9237 7.87159 24.5223 6.48506 22.8689 5.45666C21.2156 4.42826 19.3524 3.78423 17.4168 3.57211C15.4813 3.35998 13.5228 3.58517 11.686 4.23106C9.84909 4.87694 8.18069 5.92704 6.80386 7.30386L4.68629 5.18629Z" />
      </mask>
      <path
        d="M4.68629 5.18629C6.38015 3.49243 8.43274 2.20053 10.6926 1.40592C12.9524 0.611304 15.3619 0.334261 17.7431 0.595232C20.1243 0.856203 22.4165 1.64853 24.4506 2.91374C26.4847 4.17895 28.2088 5.88475 29.4956 7.90527C30.7824 9.9258 31.599 12.2095 31.8853 14.5878C32.1716 16.9661 31.9202 19.3783 31.1497 21.6465C30.3792 23.9147 29.1092 25.9809 27.4334 27.6927C25.7577 29.4045 23.719 30.7181 21.4677 31.5368L20.4443 28.7223C22.2742 28.057 23.9314 26.9892 25.2935 25.5978C26.6556 24.2064 27.6878 22.5269 28.3141 20.6832C28.9405 18.8396 29.1448 16.8789 28.9121 14.9457C28.6794 13.0125 28.0156 11.1563 26.9696 9.51394C25.9237 7.87159 24.5223 6.48506 22.8689 5.45666C21.2156 4.42826 19.3524 3.78423 17.4168 3.57211C15.4813 3.35998 13.5228 3.58517 11.686 4.23106C9.84909 4.87694 8.18069 5.92704 6.80386 7.30386L4.68629 5.18629Z"
        stroke="#1FBE58"
        stroke-width="6"
        stroke-linejoin="round"
        mask="url(#path-2-inside-1_38_114)"
      />
    </svg>
  );
};
