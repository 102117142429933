import { ModalProps } from "../types";
function Modal({ className, open, children, cancel }: ModalProps) {
  return (
    <>
      <div
        onClick={cancel}
        className={`bg-[#1e1e1eab] flex justify-center items-center px-3 h-screen w-full fixed z-30 ${
          !open ? "hidden" : ""
        } `}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`bg-white min-h-40 rounded-2xl p-3 sm:p-10 ${className}`}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;
