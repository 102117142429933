import { Button } from "../../components/button";
import Nav from "../../components/nav";
import { HiOutlineArrowRight } from "react-icons/hi2";
import HeroImg from "../../assets/img/hero-pic.png";
import Customer1 from "../../assets/img/customer-3.png";
import Customer2 from "../../assets/img/customer-1.png";
import Customer3 from "../../assets/img/customer-5.jpg";
import Customer4 from "../../assets/img/customer-2.png";
import Customer5 from "../../assets/img/customer-4.png";
import Mastercard from "../../assets/img/mastercard.png";
import LoadingImg from "../../assets/img/loading.png";
import { Line } from "../../assets/svgs/line";
import { Heart } from "../../assets/svgs/heart";
import { Users } from "../../assets/svgs/users";
import { Globe } from "../../assets/svgs/globe";
import { User } from "../../assets/svgs/user";
import { testimonial } from "../../utils/data";
import TestimonialCard from "../../components/testimonialCard";
import Footer from "../../components/footer";
import { useVisibility } from "../../provider/contexts/visibilityContext";
import CreateAccountModal from "../../components/CreateAccountModal";

function LandingPage() {
  const {setModal} = useVisibility();
  function handleClick(){
    setModal({key: 'showCreateAccountModal', value:true})
  }
  return (
    <>
    <CreateAccountModal />
    <div>
      <Nav />
      <div className="flex justify-center gap-6 px-3 sm:px-10 items-center w-auto sm:w-[36rem] lg:w-[50rem] flex-col mx-auto mt-[6rem]">
        <h1 className="font-bold sm:text-5xl text-3xl text-center">Find Your Perfect Match!</h1>
        <p className="sm:text-lg text-base text-center">
          We go beyond the ordinary in the quest for your perfect match. Our
          unique approach involves the application of Vedic Horoscope Matching.
        </p>
      </div>
        <div className="flex flex-col sm:flex-row gap-3 px-10 sm:p-0 w-auto justify-center mt-10">
          <Button variant="full" size="lg" onClick={handleClick}>
            Find My Match <HiOutlineArrowRight size={20} />
          </Button>
          <Button variant="outline" size="lg">
            Book Consultation
          </Button>
        </div>
      <div className="lg:px-20 sm:px-10 px-5 mt-10 sm:mt-20 h-[10rem] sm:h-[28rem] py-5">
        <img src={HeroImg} alt="hero" className="w-full h-full rounded-md" />
      </div>

      <div className="bg-lightGray px-5 sm:px-10 lg:px-20 py-10 mt-14">
        <Line />
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-10 xl:gap-0">
          <div className="mt-5 sm:col-span-2">
            <h2 className="font-bold sm:text-5xl text-3xl">Our Services</h2>
            <p className="font-normal text-base sm:text-lg mt-6">
              Unveil the magic of woven threads of destiny, guides you to a love
              story written in the stars.
            </p>
            <div className="mt-12 sm:space-y-10 space-y-6">
              <h3 className="font-bold text-lg ">As your cosmic matchmaker:</h3>
              <div className="flex gap-5 items-center">
                <span className="p-3 rounded-md bg-lightGreen">
                  <Heart />
                </span>
                <p className="font-normal font-lg">
                  We provide personalized matching for each heart
                </p>
              </div>
              <div className="flex gap-5 items-center">
                <span className="p-3 rounded-md bg-lightGreen">
                  <Globe />
                </span>
                <p className="font-normal font-lg">
                  Our matchmaking prowess extends beyond geographical borders.
                </p>
              </div>
              <div className="flex gap-5 items-center">
                <span className="p-3 rounded-md bg-lightGreen">
                  <Users />
                </span>
                <p className="font-normal font-lg">
                  Your journey is not just a search for a soulmate, it's a quest
                  for a happily-ever-after.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="sm:grid grid-cols-3 gap-2 justify-items-center xl:justify-items-start hidden">
              <div className="flex flex-col text-center">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl">15K</h2>
                <p className="font-thin sm:text-xl text-sm text-center">Happy stories and counting</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl text-center">98%</h2>
                <p className="font-thin text-sm sm:text-lg text-center">Compatibility rate</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl text-center">20+</h2>
                <p className="font-thin text-sm sm:text-lg text-center">Countries connected</p>
              </div>
            </div>
            <div className="sm:mt-10 mt-5">
              <img src={Customer1} alt="" className="w-full" />
            </div>
            <div className="grid grid-cols-3 mt-5 gap-2 justify-items-center xl:justify-items-start sm:hidden">
              <div className="flex flex-col text-center">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl">15K</h2>
                <p className="font-thin sm:text-xl text-sm text-center">Happy stories and counting</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl text-center">98%</h2>
                <p className="font-thin text-sm sm:text-lg text-center">Compatibility rate</p>
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold text-xl sm:text-3xl lg:text-4xl text-center">20+</h2>
                <p className="font-thin text-sm sm:text-lg text-center">Countries connected</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:px-20 px-5 sm:px-10 py-10">
        <div className="flex flex-col items-center gap-1">
          <h1 className="sm:text-5xl text-3xl text-center font-bold">How it Works</h1>
          <p className="font-thin text-base sm:text-lg text-center sm:w-[30rem] w-auto">
            Our Vedic Matchmaker API uses your horoscope to find compatible
            matches.
          </p>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 mt-10 sm:mt-32 py-10 sm:gap-32 gap-20 place-content-center">
          <div className="flex flex-col gap-3 sm:gap-5">
            <Line />
            <h3 className="font-bold text-2xl">Fill out the details</h3>
            <p className="font-thin text-base sm:text-lg sm:w-[30rem] w-auto">
              You are to fill out your partner’s preference and personal details
              in order find compatible matches.
            </p>
          </div>
          <div className="relative justify-self-center">
            <div className="flex gap-5 shadow-lg w-[320px] sm:w-[300px] xl:w-[380px] p-3 lg:p-5 rounded-lg absolute z-10 bg-white -top-10 -left-[1.3rem] sm:-left-[8rem] lg:-left-[10rem] xl:-left-[18rem]">
              <span className="bg-lightGreen h-8 w-8 flex justify-center items-center rounded-md">
                <User />
              </span>
              <div>
                <h3 className="font-bold text-base lg:text-lg">Share your vibes!</h3>
                <p className="text-sm lg:text-base font-thin">
                  Unveil the details that make you, you!
                </p>
              </div>
            </div>
            <img src={Customer2} alt="" className="" />
            <div className="flex items-center lg:items-start gap-5 shadow-lg w-[320px] sm:w-[300px] 2xl:w-[380px] p-3 lg:p-5 rounded-lg absolute z-10 bg-white -bottom-12 lg:-bottom-10 -left-[1.2rem] sm:left-[10rem] lg:left-[10rem]">
              <span className="bg-lightGreen h-8 w-8 flex justify-center items-center rounded-md">
                <Heart />
              </span>
              <div>
                <h3 className="font-bold text-base lg:text-lg">Craft your match!</h3>
                <p className="lg:text-base text-sm font-thin">
                  Shape your love story, your way, today!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 mt-20 sm:mt-32 py-10 gap-20">
          <div className="flex flex-col gap-5">
            <Line />
            <h3 className="font-bold text-2xl">Compatible profiles</h3>
            <p className="font-thin text-lg sm:w-[30rem] w-auto">
              Our API will compute your match scores with other profiles,
              allowing you to explore and pick profiles that interest you.
            </p>
          </div>
          <div  className="relative h-[20rem] w-[20rem] sm:w-[35rem] justify-self-center">
            <div className="shadow-lg p-4 sm:p-6 w-[18rem] sm:w-[30rem] rounded-xl relative h-full mx-auto">
              <div className="flex gap-2">
                <img
                  src={Customer3}
                  alt=""
                  className="sm:h-12 sm:w-12 h-10 w-10 rounded-full"
                />
                <div className="">
                  <h3 className="font-bold text-lg">Stephan Stone</h3>
                  <p className="text-lg font-thin">27yrs</p>
                </div>
                <span className="bg-lightGreen rounded-md py-1 px-2 font-thin text-primaryGreen text-base absolute top-5 right-4">
                  Excellent
                </span>
              </div>
              <p className="text-lg font-thin mt-2">
                Select based on how you want your partner's preferences
              </p>
            </div>
            <div className="rounded-xl flex gap-2 absolute w-full sm:p-5 p-3 shadow-xl z-10 bottom-5 bg-white ">
              <img src={LoadingImg} alt="" className="sm:w-20 sm:h-20 w-10 h-10" />
              <div>
                <h3 className="font-bold text-base sm:text-lg">
                  Your compatible profile awaits!
                </h3>
                <p className="sm:text-lg text-base font-thin">
                  Ascend in love with profiles tuned to your heart's melody.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 mt-10 sm:mt-32 py-10 gap-20">
          <div className="flex flex-col gap-5">
            <Line />
            <h3 className="font-bold text-2xl">Make Payment</h3>
            <p className="font-thin text-lg sm:w-[30rem] w-auto">
              Complete your payment, unlock the doors to meaningful meetings
              with your selected profile.
            </p>
          </div>
          <div className="relative justify-self-center">
            <div className="flex shadow-lg gap-0 sm:gap-5 w-[320px] sm:w-[300px] lg:w-[350px] 2xl:w-[410px] p-3 lg:p-5 rounded-lg absolute z-10 bg-white -top-12 sm:-top-10 -left-[1.2rem] lg:left-[8rem]">
              <img src={Mastercard} alt="" className="sm:w-[60px] w-[40px]" />
              <div>
                <h3 className="font-bold text-base lg:text-lg">Unlock loves's code!</h3>
                <p className="text-sm lg:text-base font-thin">
                  Seal the deal with your perfect match now
                </p>
              </div>
            </div>
            <img src={Customer4} alt="" className="" />
            <div className="flex gap-2 sm:gap-5 shadow-lg w-[250px] sm:w-[300px] lg:w-[380px] p-3 lg:p-5 rounded-lg absolute z-10 bg-white -bottom-10 sm:-bottom-10 -left-[1.2rem] sm:-left-[8rem] lg:-left-[10rem] xl:-left-[18rem]">
              <img src={Customer5} alt="" className="sm:w-[60px] w-[40px] sm:h-[60px] h-[40px]" />
              <div>
                <h3 className="font-bold lg:text-lg text-base">Your Perfect Match!</h3>
                <p className="lg:text-base text-sm font-thin">
                  98%, Score
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightGray px-5 sm:px-10 pb-20">
        <div className="flex items-center flex-col px-4 sm:px-20 py-5 sm:py-10 sm:mt-10 mt-5  gap-6">
          <div className="sm:mt-20 mt-10 flex items-center">
            <div className="h-1 w-1 rounded-full bg-primaryGreen"></div>
            <div className="h-[1px] w-32 bg-primaryGreen"></div>
            <div className="h-1 w-1 rounded-full bg-primaryGreen"></div>
          </div>
          <h1 className="font-bold text-3xl sm:text-5xl text-center">Our Stories</h1>
          <p className="font-thin text-base sm:text-lg text-center sm:w-[25.5rem] w-auto">
            Unveil the magic of woven threads of destiny, guides you to a love
            story written in the stars.
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-10">
          {testimonial.map(({ name, content, avatar, job, star }) => (
            <TestimonialCard
              name={name}
              content={content}
              avatar={avatar}
              job={job}
              star={star}
            />
          ))}
        </div>
      </div>

      <div className="sm:p-10 p-5">
        <div className="sm:w-10/12 w-auto rounded-md bg-primaryGreen flex gap-4 flex-col justify-center items-center sm:p-12 p-5  text-white mx-auto">
          <h2 className="font-bold text-4xl text-center">Ready to Find Your Match?</h2>
          <p className="font-thin text-lg text-center lg:w-[38rem] w-auto">
            Unveil the magic of woven threads of destiny, guides you to a love
            story written in the stars.
          </p>
          <Button
            variant="full"
            bgColor="bg-white"
            hoverBorderColor="border-white"
            hoverTextColor="text-white"
            textColor="text-primaryGreen"
            size="lg"
            className="mt-3 text-lg font-medium"
          >
            Get Started <HiOutlineArrowRight size={23} />
          </Button>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
}

export default LandingPage;
