import { SVGProps } from "react";
type Props = {
  width?: number;
  height?:number
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
export const Star = ({ width = 13, height=19, color = "#FADB14", className }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.7661 7.7896L8.13523 7.26191L6.51211 3.97135C6.46778 3.88126 6.39485 3.80833 6.30476 3.76399C6.07881 3.65245 5.80424 3.7454 5.69126 3.97135L4.06815 7.26191L0.437242 7.7896C0.337138 7.8039 0.245615 7.85109 0.175542 7.92259C0.090828 8.00966 0.0441467 8.1268 0.0457556 8.24828C0.0473645 8.36975 0.0971319 8.48561 0.184122 8.57041L2.81113 11.1316L2.19049 14.7482C2.17593 14.8324 2.18524 14.9189 2.21736 14.998C2.24948 15.0771 2.30312 15.1456 2.3722 15.1958C2.44129 15.246 2.52304 15.2758 2.60821 15.2819C2.69337 15.2879 2.77853 15.27 2.85403 15.2302L6.10169 13.5227L9.34934 15.2302C9.438 15.2774 9.54097 15.2931 9.63964 15.2759C9.88847 15.233 10.0558 14.9971 10.0129 14.7482L9.39224 11.1316L12.0193 8.57041C12.0908 8.50034 12.1379 8.40881 12.1522 8.30871C12.1909 8.05845 12.0164 7.82678 11.7661 7.7896Z"
        fill={color}
      />
    </svg>
  );
};
