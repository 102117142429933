import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./app/landingPage";
import Login from "./app/authentication/login";
import PageNotFound from "./app/page-not-found";
import { UserProvider } from "./provider/contexts/userContext";
import { AppInfoProvider } from "./provider/contexts/appInfoContext";
import { VisibilityProvider } from "./provider/contexts/visibilityContext";
function App() {
  return (
    <>
      <VisibilityProvider>
        <UserProvider>
          <AppInfoProvider>
            <BrowserRouter>
              <Routes>
                <Route index element={<LandingPage />} />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
          </AppInfoProvider>
        </UserProvider>
      </VisibilityProvider>
    </>
  );
}

export default App;
