import { useState } from "react";
import { NavLink } from "react-router-dom";

function Nav() {
  const [checked, setChecked] = useState(false);
  return (
    <nav className="border-b-2 flex px-5 sm:px-24 border-[#EAEAEA] py-5 justify-between relative">
      <h1 className="font-bold text-lg sm:text-2xl">Vedic Matchmaker</h1>
      <ul className="hidden sm:flex gap-3 desktop-nav">
        <li>
          <NavLink to='/' className="border-b border-secondaryGray text-secondaryGray">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to='/login' className="border-b border-secondaryGray text-secondaryGray">
            Login
          </NavLink>
        </li>
        <li>
          <NavLink to='/forgot-password' className="border-b border-secondaryGray text-secondaryGray">
            Forgot Password?
          </NavLink>
        </li>
      </ul>
      <div className="nav__icon sm:hidden sm:invisible block visible">
        <input
          type="checkbox"
          className={`navigation__checkbox none`}
          id="navi-toggle"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <label
          htmlFor="navi-toggle"
          className={`navigation__button h-[2rem] absolute z-20 w-[2rem] right-6 top-1 text-center cursor-pointer`}
        >
          <span className={`navigation__icon relative mt-[2rem]`}>&nbsp;</span>
        </label>
      </div>
      <ul
        className={`flex p-5 gap-3 flex-col transform transition-all duration-300  absolute left-0 top-[4rem] w-full z-40 bg-white mobile-nav ${
          checked ? "translate-y-0 h-screen" : "-translate-y-80"
        }  `}
      >
        <li>
          <NavLink to='/' className="text-secondaryGray p-5 block rounded-md text-xl">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to='/login' className="text-secondaryGray p-5 block rounded-md text-xl">
            Login
          </NavLink>
        </li>
        <li>
          <NavLink to='/forgot-password' className=" text-secondaryGray p-5 block text-xl">
            Forgot Password?
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
