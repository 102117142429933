import Anita from '../assets/img/anita.png';
import Dayan from '../assets/img/dayan.png';
import Marie from '../assets/img/marie.png';
import Stephen from '../assets/img/stephen.png';
import James from '../assets/img/customer-5.jpg'
export const testimonial = [
  {
    name: "James Hilton",
    job: "CE0, XYZ Company",
    star: 3.5,
    avatar: James,
    content:
      "A decade of love expertise is no small feat, and Vedic Matchmaker wears it like a badge of honor. I joined the ever-growing tapestry of success stories",
  },
  {
    name: "Anits Campbell",
    job: "Social Media Marketer",
    star: 4.5,
    avatar: Anita,
    content:
      "In a fast-paced world, Vedic Matchmaker stood out with its 3-minute registration process. Swift and efficient, yet the impact was profound.",
  },
  {
    name: "Dayan Salman",
    job: "Software Developer",
    star: 5,
    avatar: Dayan,
    content:
      "Joining Vedic Matchmaker wasn't just about finding a match, it was about joining a family of experts. Their API search of 20 matchmakers was top-notch.",
  },
  {
    name: "Stephen Stone",
    job: "CEO, Blueblack",
    star: 4.5,
    avatar: Stephen,
    content:
      "The journey was seamless, and the expert guidance turned my dream of a perfect match into a beautiful reality. I am happy I found my soulmate.",
  },
  {
    name: "Marie Andrew",
    job: "Product Designer",
    star: 2,
    avatar: Marie,
    content:
      "Having explored various platforms, I was drawn to Vedic Matchmaker for its global reach. The 27 countries connected truly spoke to me.",
  },
];
