import { MouseEventHandler, ReactNode } from "react";

type ButtonProps = {
  className?: String;
  children: ReactNode | string;
  size?: String;
  bgColor?: String;
  hoverBgColor?: String;
  variant: "full" | "outline";
  rounded?: string;
  hoverBorderColor?: String;
  hoverTextColor?: String;
  textColor?: String;
  onClick?: (prop: any) => void;
};
export const Button = ({
  className,
  children,
  size,
  bgColor = "bg-primaryGreen",
  hoverBgColor = "bg-transparent",
  variant,
  rounded = "rounded-full",
  hoverBorderColor = "border-primaryGreen",
  hoverTextColor = "text-primaryGreen",
  textColor = "text-white",
  onClick,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${className ? className : ""}  ${
        variant === "full"
          ? `${bgColor} ${textColor} border-0 hover:${hoverBgColor} hover:border hover:${hoverBorderColor} hover:${hoverTextColor}`
          : "border-[0.3px] border-primaryGreen text-primaryGreen hover:bg-primaryGreen hover:text-white "
      } outline-0 py-[4px] ${rounded} duration-500 flex justify-center gap-2 items-center    ${
        size === "sm"
          ? "px-4 h-[35px]"
          : size === "lg"
          ? "px-14 h-[54px]"
          : "px-6 h-[38px]"
      }   `}
    >
      {children}
    </button>
  );
};
