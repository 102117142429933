import { Star } from "../../assets/svgs/star";
import { CardProps } from "../../model/interface/params";
import { getPoint } from "../../utils/utility";
import HalfStar from '../../assets/img/half-star.png'
function TestimonialCard({ name, content, avatar, star, job }: CardProps) {
  return (
    <div className="bg-white p-5 sm:p-12 w-[30rem] rounded-md">
      <div className="flex justify-between flex-wrap">
        <div className="flex gap-4">
          <img src={avatar} alt="" className="h-[50px] w-[50px] rounded-full" />
          <div>
            <h3 className="font-bold text-lg">{name}</h3>
            <p className="font-thin text-base">{job}</p>
          </div>
        </div>
        <div className="flex gap-1 justify-end">
          {Array.from({ length: Math.floor(star) }, () => (
            <Star />
          ))}
         {getPoint(String(star)) ? <img src={HalfStar} alt="half-star" className="w-[13px] h-[19px]" />: null}
         {Array.from({length:(5-star) }, () => (<Star color="#F0F0F0" />))}
        </div>
      </div>
      <p className="font-normal mt-8 text-lg text-[#333]">{content}</p>
    </div>
  );
}

export default TestimonialCard;
