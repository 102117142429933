import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer className=" text-white bg-black px-5 sm:px-10 lg:px-20 sm:py-20 py-10">
      <div className="flex flex-col sm:flex-row justify-between flex-wrap gap-10">
        <div className="space-y-3 order-first">
          <h3 className="text-3xl font-bold">Vedic Matchmaker</h3>
          <p className="font-thin text-lg sm:w-[20rem] w-auto">
            Going beyond the ordinary, where stars align and souls meet
          </p>
        </div>

        <div className="space-y-3">
          <h4 className="font-bold text-lg">Help Center</h4>
          <ul>
            <li>
              <Link to="/" className="text-white font-thin text-lg">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="space-y-3">
          <h4 className="font-bold text-lg">Data Protection</h4>
          <ul>
            <li>
              <Link to="/" className="text-white font-thin text-lg">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/" className="text-white font-thin text-lg">
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
        <div className="space-y-1 sm:w-[30rem] w-full order-[-300] sm:order-last">
          <h4 className="font-bold text-lg">Stay connected!</h4>
          <p className="font-thin text-lg">
            Subscribe to receive news and updates
          </p>
          <div className="bg-white flex p-1 pl-5 gap-2 justify-between rounded-md">
            <input
              type="text"
              placeholder="Enter your email"
              className="text-slate-800 bg-transparent placeholder:font-Satoshi placeholder:font-thin placeholder:text-lg focus:outline-none border-none"
            />
            <button className="px-5 text-sm sm:px-10 py-2 sm:py-2 bg-slate-800 duration-500 hover:bg-slate-900 rounded-md text-white active:outline-none">
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <div className="mt-20 flex items-center">
      <div className="h-1 w-1 rounded-full bg-white"></div>
      <div className="h-[1px] w-full bg-white"></div>
      <div className="h-1 w-1 rounded-full bg-white"></div>

      </div>
    </footer>
  );
}

export default Footer;
