import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CustomCheckbox from "../../../components/CheckBox";
import { Link } from "react-router-dom";
import { Button } from "../../../components/button";
import { Heart } from "../../../assets/svgs/heart";
import Nav from "../../../components/nav";
function Login() {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked((prevState) => !prevState);
  };
  return (
    <>
    <Nav />
      <div className="h-screen p-5 flex justify-center items-center">
        <div className="w-[40rem]">
          <h1 className="text-2xl text-secondaryGray text-center font-bold mb-10">
            Welcome Back!
          </h1>
          <div className="flex gap-1 flex-col">
            <label htmlFor="email" className="text-lg font-thin">
              Email address
            </label>
            <div className="flex border border-[#EAEAEA] rounded-md p-5">
              <input
                placeholder="example@email.com"
                type="text"
                className="w-full bg-transparent focus:outline-none border-none"
              />
            </div>
          </div>
          <div className="flex gap-1 flex-col mt-5">
            <label htmlFor="email" className="text-lg font-thin">
              Password
            </label>
            <div className="flex justify-between items-center border border-[#EAEAEA] rounded-md p-5">
              <input
                placeholder="***********"
                type={passwordVisible ? "text" : "password"}
                className="w-full bg-transparent focus:outline-none border-none"
              />
              <>
                {passwordVisible ? (
                  <AiOutlineEye
                    color="#9D9C9C"
                    size={25}
                    onClick={() => setPasswordVisible(false)}
                    className="cursor-pointer"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    color="#9D9C9C"
                    size={25}
                    onClick={() => setPasswordVisible(true)}
                    className="cursor-pointer"
                  />
                )}
              </>
            </div>
          </div>
          <div className="mt-5 flex justify-between">
            <label className="flex items-center space-x-2 cursor-pointer ">
              {isChecked ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="16" height="16" rx="4" fill="#1FBE58" />
                  <path
                    d="M4.66663 7.4764L6.77001 9.80973L11.3333 5.14307"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <div className="w-[16px] h-[16px] rounded border border-gray-400 flex items-center justify-center bg-white focus-within:border-primaryGreen"></div>
              )}
              <span className="text-[#9D9C9C] text-base">Remember Me</span>
              <input
                type="checkbox"
                className="hidden"
                checked={isChecked}
                onChange={toggleCheckbox}
              />
            </label>
            <Link
              to="/forgot-password"
              className="text-base text-[#9D9C9C] border-b-2 "
            >
              Forgot Password?
            </Link>
          </div>
          <Button variant="full" size="lg" className="w-full mt-10">
            Login
          </Button>
          <div className="bg-lightGray p-6 rounded-md flex gap-10 mt-5">
            <span className="h-10 w-10 p-1 flex justify-center items-center  bg-lightGreen rounded-md ">
              <Heart />
            </span>
            <div>
              <h3 className="font-bold text-lg sm:text-2xl">Haven’t find a match?</h3>
              <p className="sm:text-lg text-sm font-thin">
                <Link
                  to="/"
                  className="text-primaryGreen underline underline-offset-4 decoration-primaryGreen"
                >
                  Signup here.&nbsp;
                </Link>
                We provide personalized matching for each heart
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
