import { SVGProps } from "react";
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;
export const Users = ({ size = 24, color="#1FBE58", className }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11ZM10 21C13.866 21 17 19.2091 17 17C17 14.7909 13.866 13 10 13C6.13401 13 3 14.7909 3 17C3 19.2091 6.13401 21 10 21ZM13.9614 10.8155C14.9141 9.82652 15.5 8.48172 15.5 7.00008C15.5 6.29549 15.3675 5.62184 15.1261 5.00269C16.7244 5.0688 17.9999 6.38548 17.9999 8.00008C17.9999 9.65694 16.6568 11.0001 14.9999 11.0001C14.6349 11.0001 14.285 10.9349 13.9614 10.8155ZM18.4999 16.9999C18.4999 17.5387 18.3802 18.0595 18.157 18.5515C19.8634 18.0226 20.9999 17.0776 20.9999 15.9999C20.9999 14.4937 18.7799 13.2468 15.8867 13.0325C17.4974 14.0333 18.4999 15.4409 18.4999 16.9999Z"
        fill="#1FBE58"
      />
    </svg>
  );
};
